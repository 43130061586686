.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  margin: 100px auto;
  max-width: 1200px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-width: 250px;
    height: 100%;
    max-height: 450px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  
  .card h2 {
    font-size: 15px;
    margin: 10px 0;
    text-align: center;
  }
  
  .card p {
    font-size: 20px;
    margin: 5px 0;
    text-align: justify;
  }
  
  .card button {
    padding: 0.7rem 1.1rem;
    border-radius: 50px;
    background: rgb(16, 6, 163);
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-left: 0.2rem;
    cursor: pointer;
    outline-style: none;
    /* border-style: none; */
    border: 10px solid white;
  }
  
  .card button:hover {
    background-color: rgb(16, 6, 163,0.7);
  }