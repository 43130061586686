.productsWrapper {
  flex-direction: column;
  background: linear-gradient(to right, #9393cd, #001696);
}

.productsHeading {
  margin: 75px 10px 30px 10px;
  font-size: 3rem;
  color: rgb(246, 255, 240);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-align: center;
}

.productsListWrapper {
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 90vw;
 flex-direction: row;
  background-color:linear-gradient(to right, #9393cd, #001696);

  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}

.productsList {
  width: auto;
  height: 520px;
  margin: 0 10px;
}

.productsDiv {
  flex-direction: column;
  justify-content:space-between;
  background: rgb(27, 210, 10,0.7);
  width: 300px;
  height: 100%;
  border-radius: 0.5rem;
  margin: 0 10px;
}
.selectedProduct {
  background-color: green;
  color: white;
}
.productsDiv:nth-of-type(2) {
  margin: 0px 10px;
}
.productsDiv:nth-of-type(3) {
  margin: 0px 10px;
}

.products {
  font-size: 1.5rem;
  padding: 30px 50px 0px 40px;
  font-weight: 500;
  color:rgb(231, 215, 186);
  margin: 10px;
  cursor: pointer;
}

.productsDescription {
  text-align: justify;
  font-weight: bold;
  padding: 0 50px 25px 40px;
}

.productsImg {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.products.selectedProduct {
  background-color: green;
  color: white;
}
.shopBtn {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  background: #1006a3;
  padding: 15px;
  width: 360px;
  border-radius: 1rem;
  outline-style: none;
  border: none;
  margin: 50px;
  cursor: pointer;
}
.shopBtn:hover {
  background: #039b03;
}

@media (max-width: 1100px) {
  .productsListWrapper {
    justify-content: flex-start;
  }
}

@media (max-width: 900px) {
  .productsHeading {
    font-size: 1.5rem;
  }
}

@media (max-width: 550px) {
  .productsDiv:nth-of-type(2) {
    margin: 0 10px;
  }

  .productsDiv {
    width: 300px;
  }

  .shopBtn {
    width: 90%;
  }
}


