* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #353839;
}

/* utilities */
a {
  text-decoration: none;
}

li {
  list-style: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* App.js */
.App {
  position: relative;
}

.fab {
  margin: 10px;
  color: #231f20;
}
