.featuresWrapper {
  flex-direction: column;
  background: linear-gradient(to right, #9393cd, #001696);
}

.featuresHeading {
  margin: 75px 10px 30px 10px;
  font-size: 3rem;
  color: aliceblue;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

.featuresListWrapper {
  width: 100%;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}

.featuresList {
  width: 1100px;
  height: 465px;
  margin: 0 10px;
}

.featureDiv {
  flex-direction: column;
  justify-content: space-between;
  background: #f6f7f8;
  width: 350px;
  height: 100%;
  border-radius: 0.5rem;
}

.featureDiv:nth-of-type(2) {
  margin: 0 20px;
}

.feature {
  font-size: 1.5rem;
  padding: 30px 50px 30px 40px;
  font-weight: 500;
}

.featureDescription {
  padding: 0 50px 25px 40px;
}

.featureImg {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.shopBtn {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  background: #1006a3;
  padding: 15px;
  width: 360px;
  border-radius: 1rem;
  outline-style: none;
  border: none;
  margin: 50px;
  cursor: pointer;
}
.shopBtn:hover {
  background: #039b03;
}

@media (max-width: 1100px) {
  .featuresListWrapper {
    justify-content: flex-start;
  }
}

@media (max-width: 900px) {
  .featuresHeading {
    font-size: 1.5rem;
  }
}

@media (max-width: 550px) {
  .featureDiv:nth-of-type(2) {
    margin: 0 10px;
  }

  .featureDiv {
    width: 300px;
  }

  .shopBtn {
    width: 90%;
  }
}
